
import React, { useState, useEffect } from 'react';
import {AutoComplete,  Avatar} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import { searchDB } from "services/apiHandler"
import { useNavigate } from "react-router-dom";
import debounce from 'lodash.debounce';



const SearchBar = (props) =>{
  let navigate = useNavigate();
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const [toggle, setToggle] = useState(false) 
  const searchInput = React.useRef(null);

  const onSearch = (searchText) => {
    if(searchText.length > 2){
      getData(searchText)
    } else {
      setOptions([])
    }
    

  };

  const onSelect = (data,option) => {
    previewPage(option)
    setValue("")
  };


  const previewPage = (option) =>{

    if(value.length === 6 && !isNaN(value) && option === undefined){
      navigate("/invoices/"+value)
    } else


    if(option === undefined){
      navigate("/customers/",{state:{search:value}})
    }else


    if(option.type === 'customer'){
      navigate("/customers/"+option.ClientNmr)
      
    }else

    if(option.type === "invoice"){
      navigate("/invoices/"+option.Invoice_Number_Ticket)
    }



  }

  const onChange = (data) => {
    setValue(data);
  };

  const onKeyDown = (e) =>{
    
    if (e.key === 'ArrowDown' || e.key === "ArrowUp" || e.key === "Enter") {
      setTimeout(()=>{
        previewPage(options[e.target.attributes['aria-activedescendant'].value.substring(17)])
        if(e.key === "Enter"){
          setValue("")
        }
      },1)
    }
  }

  const getData = async (t) =>{
    searchDB(t)
    .then((response)=>{

      const customerValues = response.data.customers.map(it=>({value: it.ClientNmr+" - "+it.Client_Last_Name, type:"customer", ...it}))
      const invoiceValues = response.data.invoices.map(it=>({value: it.Invoice_Number_Ticket+" - "+it.Client_Last_Name, type:"invoice", ...it}))
      setOptions(
        !t ? [] : [...customerValues, ...invoiceValues],
      );

    })
    .catch((err)=>{
      
    })
  };
  
 
 
  

  useEffect(() => {
    

      setTimeout(() => {
        if(!toggle){
          setValue("")
        }
        
    }, 1); 


      
    },[toggle]);


  return  <AutoComplete
         ref={searchInput}
        options={options}
        style={{
          width: "200px",
        }}
        size="large"
        backfill={true}
        dropdownMatchSelectWidth={false}
        onSelect={onSelect}
        onSearch={debounce(onSearch, 300)}
        onChange={onChange}
        // onBlur={()=>setToggle(false)}
        onKeyDown={onKeyDown}
        value={value}
        placeholder="Search..."
        prefix={<SearchOutlined />}
      /> 
  

}


export default SearchBar