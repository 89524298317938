import React from 'react';
import {Breadcrumb} from 'antd';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";


const DynamicBreadCrumbb = (props) =>{
    let params = useParams();
    return (
        <Breadcrumb style={{ margin: '0 0 16px  16px' }}>
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
            {
                props.bclist.map(item=>
                {
                    let name = 'dynamic' in item && item['dynamic'] === true ? params[item.name] : item.name;
                    let href = 'dynamic' in item && item['dynamic'] === true ? item.href+"/"+params[item.name] : item.href
                    
                return <Breadcrumb.Item key={href}> <Link to={href}>{name}</Link></Breadcrumb.Item> 
                
                })
            }
        </Breadcrumb>
    )
}



export default DynamicBreadCrumbb;