import { Layout} from 'antd';
import React from 'react';

import { BrowserRouter as Router} from "react-router-dom";

import Topbar from './Topbar'
import Sidebar from './Sidebar'
import Content from './Content'

const MainLayout = (props) =>{
  return <Router>
          <Layout>
            <Topbar />
            <Layout style={{paddingTop: '60px'}}>
              <Sidebar/>
              <Content />
            </Layout>  
          </Layout>
        </Router>
}


export default MainLayout;