import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from 'redux'
import globalReducer from "./global"
import userReducer from "./user"

const reducer = combineReducers({
    global: globalReducer,
    user: userReducer
})

export default configureStore({
    reducer: reducer,
    devTools: true
    
});