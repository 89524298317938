import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mobile: false,
    windowHeight : 0,
    windowWidth : 0,
    collapsed: false,
    collapsedNav: false,
    logoWidth: "80px",
    navbWidth: "200px",
    initialized: false,
  }


export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
      setWindow: (state, action) =>{
        state.windowHeight = action.payload.height
        state.windowWidth = action.payload.width
        state.collapsed = state.collapsed ? true : action.payload.width <=768  && !state.mobile ? true : false
        state.mobile = action.payload.width <=768 
        
      },
      toggle: (state) =>{
        state.collapsed = !state.collapsed
        state.collapsedNav = state.mobile ? true : state.collapsed
        state.logoWidth = state.mobile ? "80px" : state.collapsed ? "80px" : "200px"
        state.navbWidth = state.mobile ? state.collapsed ? "0" : "80px" : state.collapsed ? "80px" : "200px"
      },
      setCollapse: (state,action)=>{
        state.collapsed = action.payload
        state.collapsedNav = state.mobile ? true : state.collapsed
      },
      onBreakpoint: (state,action) =>{
        state.collapsedNav = action.payload ? true : state.collapsed
        state.navbWidth = action.payload ? state.collapsed ? "0" : "80px" : state.collapsed ? "80px" : "200px"
      },
      initialized:(state) =>{
        state.initialized = true
      }
     

      
    },
  })

export const { toggle, onBreakpoint, setWindow, setCollapse, initialized} = globalSlice.actions

export default globalSlice.reducer