import { Form, Input, Button, Checkbox } from 'antd';
import { useLogin } from "services/auth"
import { useSelector } from 'react-redux'


const LoginScreen = () => {

    const reduxState = useSelector((state) => state)
    const globalState = reduxState.global


    const Login = useLogin()

    const onFinish = async (values) => {
        await Login(values.username, values.password)
   
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

  return !globalState.initialized ?<></> :(
    <div style={{width:"100%", textAlign:"center"}}>
    <div style={{maxWidth:500, margin: "0 auto"}}>
    <Form
      name="basic"

      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>


      <Form.Item
 
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    </div></div>
  );
};

export default LoginScreen