import React from 'react';
import { Layout, Menu} from 'antd';
import { Link } from "react-router-dom";
import {SideBarLinks,MainRoutes, CustomerSideBarLinks, CustomerRoutes} from "../"
import {Routes,Route} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { onBreakpoint } from "../../redux/global"

const {Sider} = Layout;
const { SubMenu } = Menu; 

const SideBar = (props) =>{
    const reduxState = useSelector((state) => state)
    const globalState = reduxState.global
    const userState = reduxState.user
    const dispatch = useDispatch()

    const ContentRoutes = userState.job === "customer"  ? CustomerRoutes : MainRoutes
    const MainSideBarLinks = userState.job === "customer"  ? CustomerSideBarLinks : SideBarLinks
    const collapsed = userState.loggedIn ? globalState.collapsedNav : true;

    const checkPerms = (page,user) => page.length === 0 ? true : page.filter(x=> user === undefined ? [] : user.includes(x)).length > 0
     


    return <Sider trigger={null} collapsible collapsed={collapsed} 
              breakpoint="md" 
              collapsedWidth={userState.loggedIn ?  globalState.navbWidth : globalState.initialized ? 0 : globalState.navbWidth}
              onBreakpoint={(broke)=>dispatch(onBreakpoint(broke))}
              style={{
                      overflow: 'auto',
                      height: '100vh',
                      left: 0,
                      position: 'fixed'
                  }}>
            
                { userState.loggedIn &&
                                <Routes>
                                { ContentRoutes.map(rItem=>{
                                    const menuItems = MainSideBarLinks.map(item=>{
                                        const isAuth = checkPerms(item.permissions,userState.permissions)
                                        if(!isAuth){
                                            return {disabled: true}
                                        }
                                        if(item.subdir.length===0){
                                            return {key:item.url, icon:<item.icon/>, label:<Link style={{color:"white"}} to={item.url}>{item.name}</Link>  }

                                        } else {
                                            return {key:item.url, icon:<item.icon/>, label:<Link style={{color:"white"}} to={item.url}>{item.name}</Link>  }
                                            
                                            // <SubMenu key="sub1" icon={<item.icon/>} title={item.name}>
                                            //             {item.subdir.map(item2=>{
                                            //                 return <Menu.Item key={item2.url} icon={<item2.icon/>}>
                                            //                             <Link to={item2.url}>{item2.name}</Link>
                                            //                         </Menu.Item>
                                            //             })}
                                            //         </SubMenu>
                                        }
                                            
                                    }).filter(item=>!item.disabled)
                                    const el =  <Menu theme="dark" mode="inline" items={menuItems} selectedKeys={[rItem.navbarKey.toString()]}/>
                                     
                                    
                                
                                    return <Route key={rItem.url} path={rItem.url} element={el}/>
                                })}
                                
                            </Routes>
                }

              </Sider>
}



export default SideBar