import axios from "axios"
import config from "../config"
import { Upload } from 'antd';
const { Dragger } = Upload;





//****** AUTH *******/
export const loginAuth = async (user,pass) => {
  return await axios.post(config.apiURL+"/login",{username:user,password:pass})
}
export const checkToken = async(token) =>{
  return await axios.post(config.apiURL+"/login/verify",{token:token})
}

export const changePassword = async(data) =>{
  return await axios.post(config.apiURL+"/login/changePassword",{data})
}

//****** USERS *******/
export const getUsers = async () => {
  return await axios.get(config.apiURL+"/users/list/")
}

export const getCustomerUsers = async (client_nmr) => {
  const cnum = client_nmr === undefined ? "all" : client_nmr
  return await axios.get(config.apiURL+"/users/customerList/"+cnum)
}

export const getSingleUser = async (id) => {
  return await axios.get(config.apiURL+"/users/"+id)
}

export const updateUser = async (data) => {
  return await axios.post(config.apiURL+"/users/update",{data:data})
}

export const newUser = async (user) => {
  return await axios.post(config.apiURL+"/users/add",{data:user})
}



//DASH
export const getDailySalesRep = async () =>{
  return await axios.get(config.apiURL+"/dashboard/dailysalesrep")
}

export const getSnapshot = async () =>{
  return await axios.get(config.apiURL+"/dashboard/snapshot")
}

export const getSkipsShorts = async () =>{
  return await axios.get(config.apiURL+"/dashboard/skipsshorts")
}


//****** ISSUE TRACKER*****/
export const getIssueTracker = async () =>{
  return await axios.get(config.apiURL+"/issues")
}

export const getIssueTrackerByStatus = async (status) =>{
  return await axios.get(config.apiURL+"/issues/status/"+status)
}

export const getIssueTrackerComments = async (id) =>{
  return await axios.get(config.apiURL+"/issues/comments/"+id)
}

export const addIssueTrackerComments = async (data) => {
  return await axios.post(config.apiURL+"/issues/comments/add",data)
}



//****** SEARCH *******/
export const searchDB = async (search) => {
  return await axios.post(config.apiURL+"/search",{search:search})
}


//****** DAILY *******/
export const getRBO = async (date) => {
  return await axios.get(config.apiURL+"/ratboard/orderTracker/",{params:{data:date}})
}


export const updateOrderTracker = async (data) => {
  return await axios.post(config.apiURL+"/ratboard/updateNote/",{data:data})
}

export const getDailyStats = async (data) => {
  return await axios.get(config.apiURL+"/customers/stats/",{params:data})
}


export const getDilutionStats = async (data) => {
  return await axios.get(config.apiURL+"/customers/dilution/",{params:data})
}

//****** INVOICES *******/


export const getInvoiceList = async (clientId,tableInfo) => {
  return await axios.post(config.apiURL+"/invoices/list/",{tableInfo:tableInfo,customer:clientId })
}

export const getInvoice = async (invoice) => {
  return await axios.get(config.apiURL+"/invoices/"+invoice)
}

export const getPDFInvoice = async (invoice) => {
  return await axios.get(config.apiURL+"/invoices/pdf/"+invoice)
}

export const getInvoiceComments = async (invoice,revnum) => {
  return await axios.get(config.apiURL+"/invoices/comments/",{params:{invoice:invoice, revnum:revnum}})
}


export const addInvoiceComments = async (data) => {
  return await axios.post(config.apiURL+"/invoices/comments/add",data)
}

export const deleteInvoiceComments = async (id) => {
  return await axios.post(config.apiURL+"/invoices/comments/delete",{id:id})
}

//****** POS */
export const getPOList = async (tableInfo) => {
  return await axios.post(config.apiURL+"/purchaseorders/list/",{tableInfo:tableInfo })
}

export const getNL = async (tableInfo) => {
  return await axios.post(config.apiURL+"/purchaseorders/nllist/",{tableInfo:tableInfo })
}

export const getPOItems = async (ponumber,tableInfo) => {
  return await axios.post(config.apiURL+"/purchaseorders/detail/",{tableInfo:tableInfo, PONum: ponumber })
}

//****** REVISIONS *******/
export const getRevisionHeaders = async (invoice) => {
  return await axios.get(config.apiURL+"/revisions/summary/"+invoice)
}


export const getRevisionByInvoice = async (invoice,revnum) => {
  return await axios.get(config.apiURL+"/revisions/details/"+invoice+"/"+revnum)
}

export const updateRevision = async (data,log) => {
  return await axios.post(config.apiURL+"/revisions/update/",{data:data,log:log===undefined ? true : log})
}


export const updateRevisionStatus = async (data) => {
  return await axios.post(config.apiURL+"/revisions/updatestatus/",{data:data})
}

export const updateRevisionListStatus = async (data) => {
  return await axios.post(config.apiURL+"/revisions/updateliststatus/",{data:data})
}


export const addRevision = async (invoice) => {
  return await axios.post(config.apiURL+"/revisions/add/"+invoice)
}

export const getRevisionsByStatus = async (status) => {
  return await axios.get(config.apiURL+"/revisions/list/"+status)
}

export const getRevisionCounts = async () => {
  return await axios.get(config.apiURL+"/revisions/list/")
}







//****** AGING *******/
export const getAgingList = async () =>  await axios.get(config.apiURL+"/aging")

//****** CUSTOMERS *******/
//CUSTOMER LIST
export const getCustomerList = async (search) => {
  return await axios.get(config.apiURL+"/customers",{params:{search:search}})
}


export const getInactiveList = async () => {
  return await axios.get(config.apiURL+"/customers/inactives")
}

//CUSTOMER PAGE
export const getCustomerPage = async (id) => {
  return await axios.get(config.apiURL+"/customers/page/"+id)
}

///CUSTOMER STATUS
export const updateCustomerStatus = async (clientid,status) => {
  return await axios.post(config.apiURL+"/customers/status/update",{clientid:clientid,status:status})
}


export const getCustomerChecks = async (ClientNmr) => {
  return await axios.get(config.apiURL+"/payments/customer/"+ClientNmr)
}

export const getCustomerCheckDetails = async (data) => {
  return await axios.get(config.apiURL+"/payments/details/",{params:data})
}


export const getInvoiceCheckDetails = async (invoice) => {
  return await axios.get(config.apiURL+"/payments/invoice/"+invoice)
}



export const getCustomerDilution = async (data) => {
  return await axios.get(config.apiURL+"/customers/dilution/",{params:data})
}


// {params:{invoice:invoice, revnum:revnum}}
///NOTES///
export const addCustomerNote = async (clientid,note,status) => {
  return await axios.post(config.apiURL+"/customers/notes/add",{clientid:clientid,note:note,status:status})
}
export const updateCustomerNote = async (id,note,status) => {
  return await axios.post(config.apiURL+"/customers/notes/update",{id:id,note:note,status:status})
}

export const getCustomerNotes = async (ClientNmr) => {
  return await axios.get(config.apiURL+"/customers/notes/"+ClientNmr)
}


export const getSingleNote = async (id) => {
  return await axios.get(config.apiURL+"/customers/notes/id"+id)
}



///CONTACTS///
export const addCustomerContact = async (clientId) => {
  return await axios.post(config.apiURL+"/customers/contacts/add",{ClientNmr:clientId})
}

export const deleteCustomerContact = async (id) => {
  return await axios.post(config.apiURL+"/customers/contacts/delete",{id:id})
}
export const updateCustomerContact = async (data) => {
  return await axios.post(config.apiURL+"/customers/contacts/update",data)
}

export const getCustomerContacts = async (ClientNmr) => {
  return await axios.get(config.apiURL+"/customers/contacts/"+ClientNmr)
}


export const getSingleContact = async (id) => {
  return await axios.get(config.apiURL+"/customers/contacts/id"+id)
}


///UPLOAD///
export const getUploadedImage = async (filename) =>{
  return await axios.get(config.apiURL+"/upload/get/"+filename,{responseType: "arraybuffer",})
}

export const getUploadedInvoiceImageNames = async (invoice) =>{
  return await axios.get(config.apiURL+"/upload/invoice/"+invoice)
}

export const Uploader = (props) =>{
  return <Dragger
        action= {config.apiURL+"/upload"}
        headers = {{Authorization:axios.defaults.headers.common['Authorization']}}
       {...props}
       
      />
}
// getUnread
// getMessageList
// getUnaddedContacts

///Messages///
export const getUnread = async () =>{
  return await axios.get(config.apiURL+"/messages/getUnread/")
}
export const getMessageList = async () =>{
  return await axios.get(config.apiURL+"/messages/getMessageList/")
}
export const getUnaddedContacts = async () =>{
  return await axios.get(config.apiURL+"/messages/getUnaddedContacts/")
}