import React from 'react';

class Logo extends React.Component {
    
    render(){
        return  <div 
        
            onClick={this.props.onClick}
            style={{
                left:0
            }}
            >
        {this.props.collapsed ? (
            <img src={ "/img/iconlogo.png"} alt={''} 
                style={ {
                    height : '55px',
 
                    paddingLeft: "18px"
                } } />
        ) : (
            <img 
                src={  "/img/mainlogo.png"} 
                alt="flag"
                style={ {
                    width : '200px', 
 
                    padding: 8 
            }} />
        )}
      </div>
    }
}

export default Logo
