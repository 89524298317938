
import React, { useState  } from 'react';
import { Layout, Avatar, Row, Col,Dropdown, Menu} from 'antd';
import { UserOutlined, Modal, MessageOutlined, SearchOutlined} from '@ant-design/icons';
import Logo from './logo';
import SearchBar from "./Search"
import { useSelector, useDispatch } from 'react-redux'
import { toggle } from "../../redux/global"
import { useLogout } from "services/auth"
import ChangePassword from "./ChangePassword"

const { Header} = Layout;


const TBar = (props) =>{
    const [visible, setVisible] = useState(false);
    const reduxState = useSelector((state) => state)
    const globalState = reduxState.global
    const userState = reduxState.user
    const dispatch = useDispatch()
    
    const logoCollapsed = globalState.mobile ? true : globalState.collapsed

    const Logout = useLogout()
    const menu = <Menu
          items={[
            {
              label: (
                <div onClick={()=>{setVisible(true)}}>
                  Change Password
                </div>
              ),
            },
            {
              label: (
                <div onClick={()=>Logout()}>
                Logout
              </div>

              ),
            }
          ]}
        />


    return <Header className="site-layout-background" style={{ position: 'fixed', zIndex: 1, width: '100%', padding: 0 }}>
        <ChangePassword visible={visible} onClose={()=>setVisible(false)}></ChangePassword>
        <Row>
            <Col flex={globalState.logoWidth} >
                <Logo collapsed={logoCollapsed} onClick={()=>{
                
                    dispatch(toggle())
                    }}/>
            </Col>
            { userState.loggedIn &&
            
            
            <Col flex="auto">

                <div style={{textAlign:"right",  paddingRight:"12px"}}>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Avatar type="primary" shape="circle" icon={<UserOutlined />} size="large" style={{margin:4, backgroundColor: "white", color: '#1890ff'}}/>
                    </Dropdown>
                    <SearchBar/>
                    {/* <Avatar type="primary" shape="circle" icon={<NotificationOutlined />} size="large" style={{margin:4, backgroundColor: '#1890ff'}} />
                    <Avatar type="primary" shape="circle" icon={<MessageOutlined />} size="large" style={{margin:4, backgroundColor: '#1890ff'}}/> */}
          
         
                </div>
            </Col>
            }
        </Row>
        </Header>
    
}






export default TBar