const apiURL = "https://api.greatoceans.com"
const siteURL = "https://portal.greatoceans.com"


export {  
  apiURL,
  siteURL,
  };

export default {
  apiURL,
  siteURL
  
};
