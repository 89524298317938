import React, { useState,useEffect } from 'react';
import { Modal, Input, Space, Radio, Row, Col,Tag, Tooltip  } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import { changePassword } from "services/apiHandler"



const ChangePasswordModal = (props) => {
  
    const defaultState = { currentPassword:"",newPassword:"",newPasswordConfirm:"", isModalVisible:false, validation: false, match: true, errorMessage:""}
    
    const [state, setState] = useState(defaultState)


    


    useEffect(()=>{
        validate()
    },[state.newPassword,state.newPasswordConfirm,state.currentPassword,])

    useEffect(()=>{
        setState({...state, isModalVisible:props.visible})
    },[props])

    const showModal = () => setState({...state, isModalVisible :true})
    const handleOk = async () => {

        changePassword(state).then(it=>{
            if(it.data.success){
                handleCancel()
            }
            else{
                setState({...state, validation:false, errorMessage:it.data.message})
            }
            
        })
     };
    const handleCancel = () => {
        props.onClose()
        setState(defaultState)
    }


    const onCurrentChange = (e) =>{

        setState({...state, currentPassword:e.target.value})
    }
    const onNewChange = (e) =>{
        setState({...state, newPassword:e.target.value})
    }

    const onConfirmChange = (e) =>{
        setState({...state, newPasswordConfirm:e.target.value})
    }

    const validateLength = (val) =>{
        return val.length >=2
    }

    const validate = () =>{
        const lengths = validateLength(state.currentPassword) && validateLength(state.newPassword) && validateLength(state.newPasswordConfirm) 
        const match = state.newPassword === state.newPasswordConfirm
        var message = !match ? "New passwords must match. " : "" 
        message += !lengths ? "Password must be 6 or more characters" : ""
        setState({...state, validation: lengths && match, match: match,errorMessage:message})

    }


    return (
      <>
        
        <Modal  title="Change Password?" visible={state.isModalVisible} onOk={handleOk} onCancel={handleCancel} okButtonProps={{ disabled: !state.validation }}>
            <Space direction="vertical">
                <Input.Password
                
                placeholder="Current Password"
                onChange={onCurrentChange}
                value={state.currentPassword}
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
                <Input.Password
                placeholder="New Password"
                status={state.match ? "" : "error"}
                onChange={onNewChange}
                value={state.newPassword}
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />           
                <Input.Password
                placeholder="Confirm Password"
                onChange={onConfirmChange}
                status={state.match ? "" : "error"}
                value={state.newPasswordConfirm}
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
                <h5 style={{color:"red"}}>{state.errorMessage}</h5>
            </Space>
        </Modal>
      </>
    );
  };

export default ChangePasswordModal