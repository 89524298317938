import './assets/css/App.less';
import MainLayout from "navigation/MainLayout/MainLayout"
import useWindowDimensions from "utils/WindowDimensions"
import { setWindow, initialized } from "redux/global"
import { useSelector, useDispatch } from 'react-redux'
import React, {useEffect, useState} from 'react';
import {  useCheckToken } from "services/auth"
// import {  initiateSocket } from "utils/socketManager"

// import {io} from 'socket.io-client'


// const SocketManager = () =>{
//   const socket = io('https://api.bigblueocean.net')

// }



const App =() => {
  const dispatch = useDispatch()
  const { height, width } = useWindowDimensions();
  const checkToken = useCheckToken()
  const [time, setTime] = useState('fetching') 

  dispatch(setWindow({width: width, height:height}))

  const reduxState = useSelector((state) => state)
  const userState = reduxState.user

  useEffect(()=>{
  },[])

  useEffect(()=>{
    // initiateSocket()

    if(userState.loggedIn){

    }
  },[userState.loggedIn])


  useEffect(()=>{
    if(userState.loggedIn == false){

      checkToken()

      
      
    }
  },[])

  return <MainLayout/>

}

export default App;
