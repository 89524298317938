import React from 'react';
import { Layout} from 'antd';
import { MainRoutes,CustomerRoutes } from "../routes"
import {Routes, Route } from "react-router-dom";
import Breadcrumb from './Breadcrumb'
import { useSelector } from 'react-redux'
import Login from "pages/Login"

const { Content} = Layout;


const checkPerms = (page,user) => page.length === 0 ? true : page.filter(x=> user === undefined ? [] : user.includes(x)).length > 0


const Contentt = () => {
    const reduxState = useSelector((state) => state)
    const globalState = reduxState.global
    const userState = reduxState.user


    const ContentRoutes = userState.job === "customer"  ? CustomerRoutes : MainRoutes

        return !globalState.initialized ? <></> : <Layout className="site-layout" style={{ marginLeft: userState.loggedIn ?  globalState.navbWidth : globalState.initialized ? 0 : globalState.navbWidth }}>
        <Content style={{ margin: globalState.mobile ? '16px 2px' : '24px 16px', overflow: 'initial',  }}>
            <Routes>
                    {
                        ContentRoutes.map(item=>{
                            const isAuth = checkPerms(item.permissions,userState.permissions)
                            if(!isAuth){
                                return
                            }
                            const Page = isAuth < 0 ?  Login :  item.component 
                            const element = <div>
                                        {userState.loggedIn ? item.bc ?<Breadcrumb  bclist={item.bc}/>:null : null}
                                        <div className="site-layout-background" style={{ overflow:"auto", padding: item.fullscreen !== undefined && item.fullscreen ? 0 : globalState.mobile ? 0  : 24 , textAlign: 'center', height: item.bc ? globalState.windowHeight-146 : globalState.windowHeight-108 }}>
                                        

                                        <React.Suspense fallback={<>...</>}>
                                            {userState.loggedIn ? <Page/> : <Login/>}
                                            
                                        </React.Suspense>
                                 
                                        </div>
                                    </div>
                            return <Route key={item.url} path={item.url} element={element} />
                        })
                    }
            </Routes>
        </Content>
        </Layout>
    
}

export default Contentt