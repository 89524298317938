import axios from "axios"
import Cookies from 'js-cookie';
import {loginAuth, checkToken} from "services/apiHandler"
import { useSelector, useDispatch } from 'react-redux'
import { logIn, logOut } from "redux/user"
import { setWindow, initialized } from "redux/global"

export const useUserPerms = () =>{
    const reduxState = useSelector((state) => state)
    const userState = reduxState.user

    const getUserPerms = () =>{
        return userState
    }

    return getUserPerms
}


export const getToken = () =>{
    var token = Cookies.get("token")
    if(Cookies.get("token") === undefined){
        setAuth(false)
        return false
    } else {
        axios.defaults.headers.common['Authorization'] =  "Bearer "+token
        return token
    }
}


export  const useCheckToken = () =>{
    const dispatch = useDispatch()

    
    try{
        const ccheckToken = async() =>{
        
            var token = getToken()
            checkToken(token).then(result=>{
                setAuth(result.data.success, result.data.success ? result.data.token : "")
            
                if(result.data !== false){
                    dispatch(logIn(result.data))
                    dispatch(initialized())
                } else throw ""

            }).catch(err=>{
                dispatch(initialized())
                setAuth(false)
            })
        }
    
        return ccheckToken
    }
    catch (error){
        dispatch(initialized())
        
        setAuth(false)
    }

}

export const useLogout = () =>{
    const dispatch = useDispatch()
    var Logout = async () =>{
        await dispatch(logOut())
        await setAuth(false)
    }

    return Logout
}


export const useLogin = () =>{
    const dispatch = useDispatch()
    const Login = async(user,pass) =>{
    
        var result = await loginAuth(user,pass)
        setAuth(result.data.success, result.data.success ? result.data.token : "")
        await dispatch(logIn(result.data))
    }

    return Login
}

export const checkAuth = async () => {
    var token = Cookies.get("token")
    if(Cookies.get("token") === undefined){
        setAuth(false)
        return false
    }
    
    
    const result = await checkToken(token)
    setAuth(result.data.success, result.data.success ? result.data.token : "")
    return(result.data)
      
}





const setAuth = (auth,token) =>{

    if(auth){ 
        Cookies.set("token",token, { expires: 365 })
        axios.defaults.headers.common['Authorization'] =  "Bearer "+token
        
    } else {
        Cookies.remove("token")
        axios.defaults.headers.common['Authorization'] = "NOTOKEN"
    }

    return auth
}
  
  

  

      
  
  

  
  

  
  
  