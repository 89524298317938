import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store'
import { Provider } from "react-redux"
import { IoProvider } from 'socket.io-react-hook';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <IoProvider>
        <App />
      </IoProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')



);



// const container = document.getElementById('root')


// const root = ReactDOMClient.createRoot(container);
// const Ap = () => 
// root.render(<React.StrictMode>
//   <Provider store={store}>
//     <IoProvider>
//       <App />
//     </IoProvider>
//   </Provider>
//   </React.StrictMode>)

  
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
