import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userid: 0,
    loggedIn: false,
    username: "",
    fullname: "",
    job:"",
    permissions: [],
    roles: [],
    ClientNmr: 0,
  }


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      logOut:(state)=>{
        state.userid = 0
        state.loggedIn = false
        state.username = ""
        state.fullname =  ""
        state.permissions = []
        state.roles = []
        state.job= ""
        state.ClientNmr= 0
      },
      logIn:(state,action) =>{
        if(action.payload.success === false){
          state.userid = 0
          state.loggedIn = false
          state.username = ""
          state.fullname =  ""
          state.permissions = []
          state.roles = []
          state.job= ""
          state.ClientNmr= 0
        } else {
          state.userid = action.payload.user.id
          state.loggedIn = true
          state.username = action.payload.user.username
          state.fullname =  action.payload.user.name
          state.permissions = action.payload.user.permission
          state.roles = action.payload.user.role
          state.job= action.payload.user.job
          state.ClientNmr= action.payload.user.ClientNmr
        }

      }
     

      
    },
  })

export const { logIn, logOut} = userSlice.actions

export default userSlice.reducer